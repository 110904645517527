import { PageProps } from 'gatsby';
import { CollectionData, DocumentData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Form from '~/containers/Espace/Form';
import params from '~/params/note.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceCollectionDocumentUpdate: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, params: { espaceId, collectionId, documentId }, user }) => {
  const [model, setModel] = useState<any>();

  useEffect(() => {
    let isMounted = true;
    const collectionData = new CollectionData({
      espaceId: espace.id,
    });

    collectionData
      .findById(collectionId)
      .then(document => {
        if (isMounted) {
          const newModel = new DocumentData({
            params,
            ...document,
            espaceId,
            parentCollectionName: 'collections',
            parentId: collectionId,
          });

          setModel(newModel);
        }

        return true;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [collectionId]);

  if (!model) {
    return null;
  }

  return (
    <TemplateEspace espace={espace} user={user}>
      <Form
        docId={documentId}
        model={model}
        parentCollectionName="collections"
        parentId={collectionId}
        type="update"
      />
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceCollectionDocumentUpdate);
